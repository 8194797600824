import axios, { AxiosInstance } from 'axios';
import { message } from 'ant-design-vue'
import { useLoading } from '@/components/loading/loading'
import LocalStorage from './localStorage';
import { toUrl } from '@/utils/utils'
import { useLogin } from '@/components/login/userLogin.ts'
const Loading = useLoading()
// const baseURL = '//api.teaxia.com'; // 开发
const baseURL = 'https://api.teach.ac.cn'; // 正式
const uploadURL = '/api/upload/image'
class http {
	static interface: AxiosInstance = axios.create({
			baseURL: baseURL,
			timeout: 5000,
			headers: {
				'Content-Type': 'application/json',
			},
	});
	// 拦截器
	static interceptors() {
		// 请求拦截器
		http.interface.interceptors.request.use((config)=> {
			config.data = config.data?.data
			Loading.show()
			return config;
		}, function (error) {
			console.log(error);
			return Promise.reject(error);
		});
		// 返回拦截器
		http.interface.interceptors.response.use(function (response) {
			Loading.hide()
			const { data } = response
			switch(data.code){
				case -1:
				// 登录超时
					message.error(data?.msg)
					LocalStorage.clearItem('userInfo')
					console.log('清除了登录信息')
					toUrl('Home')
					useLogin().show()
					// 弹出登录框
					return Promise.reject(data.msg);
				break;
				case 0:
				// 登录系统出错
					message.error(data?.msg)
					if(data?.msg == '请求参数缺token'){
						toUrl('Home')
					}
					return Promise.reject(data.msg);
				break;
			}
			return data;
		}, function (error) {
			Loading.hide()
			console.log(error.response.data.message)
			message.error('有时候……硅基，也会出错……')
			return Promise.reject(error);
		},

		);
	}

	static get(url:string,params?: Record<string, any>) {
		return new Promise((resolve, reject) => {
				http.interface.get(url, {
						params: params,
						responseType: 'json',
						headers: {
							// 'Content-Type': 'application/json',
							'token':http.getToken()?.token
						},
				}).then(response => {
						resolve(response.data);
				}).catch(err => {
						reject(err);
				});
		});
	}

	static post(url:string,data?: Record<string, any>) {
		return new Promise((resolve, reject) => {
			http.interface.post(url, {
				data:data,
			},{
				headers: {
					'token':http.getToken()?.token
				},
			}
			).then(response => {
				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		});
	}

	static uploadFile({file}: any) {
		return new Promise((resolve, reject) => {
			const formData = new FormData()
			formData.append('file', file)
			http.interface.post(uploadURL,{data:formData},{
				headers: {
					'Content-Type': 'multipart/form-data',
					'token':http.getToken()?.token
				},
			}).then(response => {
				resolve(response.data);
			}).catch(err => {
				reject(err);
			});
		})
	}
	static getToken(){
		const token = LocalStorage.getItem('userInfo')
		return token
	}
}

http.interceptors();

export default http;
